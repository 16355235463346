// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
import * as sharedStyle from "../css/QPTmydKPK";
const HeroFonts = getFonts(Hero);

const enabledGestures = {drsJvTuEN: {hover: true}};

const serializationHash = "framer-PkdrX"

const variantClassNames = {drsJvTuEN: "framer-v-1ivxuew"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({buttonText, color, gap, height, id, link, newTab, width, ...props}) => { return {...props, eIAqZ2GrU: gap ?? props.eIAqZ2GrU ?? 6, HhBJOA5mX: buttonText ?? props.HhBJOA5mX ?? "View All Blogs", rKzQouqOK: newTab ?? props.rKzQouqOK, sUEf2TREN: color ?? props.sUEf2TREN ?? "var(--token-8d7e0ee3-cbeb-4f0c-8fae-b1180c2c0a4a, rgb(173, 176, 183))", vv0GcubLY: link ?? props.vv0GcubLY} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;newTab?: boolean;buttonText?: string;gap?: number;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, vv0GcubLY, rKzQouqOK, HhBJOA5mX, eIAqZ2GrU, sUEf2TREN, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "drsJvTuEN", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={vv0GcubLY} nodeId={"drsJvTuEN"} openInNewTab={rKzQouqOK}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1ivxuew", className, classNames)} framer-j255z7`} data-framer-name={"Text Link Button"} layoutDependency={layoutDependency} layoutId={"drsJvTuEN"} ref={ref ?? ref1} style={{"--1jo1md0": eIAqZ2GrU, ...style}} {...addPropertyOverrides({"drsJvTuEN-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-jycf3n"} data-styles-preset={"QPTmydKPK"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-sUEf2TREN-Y0iXsNkIH))"}}>View All Blogs</motion.p></React.Fragment>} className={"framer-jkdysi"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"pwvkkWs6w"} style={{"--extracted-r6o4lv": "var(--variable-reference-sUEf2TREN-Y0iXsNkIH)", "--framer-paragraph-spacing": "0px", "--variable-reference-sUEf2TREN-Y0iXsNkIH": sUEf2TREN}} text={HhBJOA5mX} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-k4hh8y"} data-framer-name={"Sub Container"} layoutDependency={layoutDependency} layoutId={"nxZcVT341"}><ComponentViewportProvider ><motion.div className={"framer-nmipuf-container"} layoutDependency={layoutDependency} layoutId={"uE_JYqRjF-container"}><Hero color={"var(--token-c40dec17-ad9b-478b-a7f0-e25384b15814, rgb(255, 255, 255))"} height={"100%"} iconSearch={"Home"} iconSelection={"ChevronRight"} id={"uE_JYqRjF"} layoutId={"uE_JYqRjF"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PkdrX.framer-j255z7, .framer-PkdrX .framer-j255z7 { display: block; }", ".framer-PkdrX.framer-1ivxuew { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: calc(max(0, var(--1jo1md0)) * 1px); height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-PkdrX .framer-jkdysi { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-PkdrX .framer-k4hh8y { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 14px; }", ".framer-PkdrX .framer-nmipuf-container { flex: none; height: 11px; position: relative; width: 11px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PkdrX.framer-1ivxuew, .framer-PkdrX .framer-k4hh8y { gap: 0px; } .framer-PkdrX.framer-1ivxuew > * { margin: 0px; margin-left: calc(calc(max(0, var(--1jo1md0)) * 1px) / 2); margin-right: calc(calc(max(0, var(--1jo1md0)) * 1px) / 2); } .framer-PkdrX.framer-1ivxuew > :first-child, .framer-PkdrX .framer-k4hh8y > :first-child { margin-left: 0px; } .framer-PkdrX.framer-1ivxuew > :last-child, .framer-PkdrX .framer-k4hh8y > :last-child { margin-right: 0px; } .framer-PkdrX .framer-k4hh8y > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ".framer-PkdrX.framer-v-1ivxuew.hover .framer-k4hh8y { justify-content: flex-end; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 130.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"BB2uVtEGD":{"layout":["auto","auto"]}}}
 * @framerVariables {"vv0GcubLY":"link","rKzQouqOK":"newTab","HhBJOA5mX":"buttonText","eIAqZ2GrU":"gap","sUEf2TREN":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerY0iXsNkIH: React.ComponentType<Props> = withCSS(Component, css, "framer-PkdrX") as typeof Component;
export default FramerY0iXsNkIH;

FramerY0iXsNkIH.displayName = "Button/Text Link Button";

FramerY0iXsNkIH.defaultProps = {height: 27, width: 130.5};

addPropertyControls(FramerY0iXsNkIH, {vv0GcubLY: {title: "Link", type: ControlType.Link}, rKzQouqOK: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}, HhBJOA5mX: {defaultValue: "View All Blogs", displayTextArea: false, title: "Button Text", type: ControlType.String}, eIAqZ2GrU: {defaultValue: 6, min: 0, title: "Gap", type: ControlType.Number}, sUEf2TREN: {defaultValue: "var(--token-8d7e0ee3-cbeb-4f0c-8fae-b1180c2c0a4a, rgb(173, 176, 183)) /* {\"name\":\"Dark/70\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerY0iXsNkIH, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...HeroFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})